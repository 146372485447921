import Vue from 'vue'
import App from './App.vue'
import './assets/util/rem'
import { Carousel,CarouselItem } from 'element-ui';

Vue.config.productionTip = false
Vue.component(Carousel.name, Carousel);
Vue.component(CarouselItem.name, CarouselItem);

new Vue({
  render: h => h(App),
}).$mount('#app')
