<template>
    <div>
        <div class="page">
            <div class="gyt-header-box">
                <img class="gyt-header-box-img" src="../assets/image/logo.png" />
                <div>上海国延堂医药科技有限公司</div>
            </div>
            <div class="gyt-page1-img-box">
                    <el-carousel trigger="click" height="40rem">
                        <el-carousel-item v-for="(item,inedx) in 2" :key="inedx">
                            <img class="gyt-page1-img" v-show="inedx==0" src="../assets/image/1.jpg" />
                            <img class="gyt-page1-img" v-show="inedx==1" src="../assets/image/2.jpg" />
                        </el-carousel-item>
                    </el-carousel>
<!--                <img class="gyt-page1-img" src="../assets/image/headerImg.jpg" />-->
            </div>
        </div>

        <div class="page gyt-page-content">
            <div class="gyt-page1-text">
                <div>给家人健康，我们更放心</div>
            </div>
            <div class="gyt-page2-box">
                <div class="gyt-page2-boxs">
                    <div>
						<span class="gyt-page2-box-title">
							<div class="gyt-page2-box-num">A </div>
							<div style="width: 80%;">改善热病津伤，口干烦渴</div>
						</span>
                        铁皮石斛长于生津止渴；主治热病伤津，烦渴、舌干苔黑之证。常与天花粉、 鲜生地、麦冬等品同用。
                    </div>
                    <div>
						<span class="gyt-page2-box-title">
							<div class="gyt-page2-box-num">C </div>
							<div style="width: 80%;">改善阴虚火旺，骨蒸劳热</div>
						</span>
                        铁皮石斛能滋肾阴，兼能降虚火，适用于阴虚火旺，骨蒸劳热等证。肾虚火 旺，骨蒸劳热者，宜与生地黄、枸杞子、黄柏、胡黄连等滋肾阴、退虚热之品同用。
                    </div>
                </div>
                <img class="gyt-page2-img" src="../assets/image/shop01.jpg" />
                <div class="gyt-page2-boxs">
                    <div>
						<span class="gyt-page2-box-title">
							<div class="gyt-page2-box-num">B </div>
							<div style="width: 80%;">改善胃阴不足，食少干呕，病后虚热不退</div>
						</span>
                        铁皮石斛长于滋养胃阴，兼能清胃热。治胃热阴虚之胃脘疼痛、牙龈肿痛、口 舌生疮可与生地、麦冬、黄芩等品同用。
                    </div>
                    <div>
						<span class="gyt-page2-box-title">
							<div class="gyt-page2-box-num">D </div> 改善目暗不明，筋骨痿软
						</span>
                        铁皮石斛适用于肾阴亏虚之目暗不明、筋骨痿软等证。肾阴亏虚，目暗不明 者，常与枸杞子、熟地黄、菟丝子等品同用。
                    </div>
                </div>
            </div>
        </div>

        <div class="page gyt-page-content gyt-page-back">
            <div class="gyt-page1-text gyt-text-c">好品质，选国延堂</div>
            <div class="gyt-page3-box">
                <img class="gyt-page3-img" src="../assets/image/shop02.jpg" />
                <div class="gyt-page2-boxs gyt-page3-text-box">
                    <div class="gyt-page3-text gyt-text-c">
                        <div class="gyt-page3-num">01</div>
                        <div>
                            <div class="gyt-page2-box-title gyt-text-c">增强免疫系统功能</div>
                            冬虫夏草主要含有冬虫夏草素、虫草酸、腺苷和多糖、等成分；冬虫夏草素能 抑制链球菌、鼻疽杆菌炭疽杆菌等病菌的生长，又是抗癌的活性物质，对人体的内分泌系
                            统和神经系统有好的调节作用；虫草酸能改变人体微循环，具有明显的降血脂和镇咳祛痰 作用；虫草多糖是免疫调节剂，可增强机体对病毒及寄生虫的抵抗力。
                        </div>
                    </div>
                    <div class="gyt-page3-text gyt-text-c">
                        <div class="gyt-page3-num">02</div>
                        <div>
                            <div class="gyt-page2-box-title gyt-text-c">改善久咳虚喘，劳嗽咯血、补肾益肺，止血化痰</div>
                            虫草含有维生素Ｂ12、脂肪、蛋白等。适用于治疗肺气虚和肺肾两虚、肺结核 等所致的咯血或痰中带血、咳嗽、气短、盗汗。
                        </div>
                    </div>
                    <div class="gyt-page3-text gyt-text-c">
                        <div class="gyt-page3-num">03</div>
                        <div>
                            <div class="gyt-page2-box-title gyt-text-c">改善肾虚精亏，腰膝酸痛，阳痿遗精</div>
                            虫草体内含虫划酸。性甘、温平、无毒，是著名的滋补强壮药，常用肉类炖 食，有补虚健体之效。对肾虚阳萎、腰膝酸疼等亦有良好的疗效，也是老年体弱者的滋补 佳品。
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="page gyt-page-content">
            <div class="gyt-page1-text">国延堂三大承诺</div>
            <div class="gyt-page4-box">
                <div class="gyt-page3-boxs">
                    <div class="gyt-page4-num">A</div>
                    <div class="gyt-page2-box-title">产地直选</div>
                    <div class="gyt-page4-text">直接与当地的生产企业进行合作，品类多样、货源丰富。源头采购能保证产品 质量并能减少中间商差价，与购买者利益共存，健康共享。</div>
                </div>
                <div class="gyt-page3-boxs">
                    <div class="gyt-page4-num">B</div>
                    <div class="gyt-page2-box-title">货品优选</div>
                    <div class="gyt-page4-text">通过不同产地间的产品比较，选出相同品级中，品质优良、价格实惠的产品， 给到购买者更多的选择。</div>
                </div>
                <div class="gyt-page3-boxs">
                    <div class="gyt-page4-num">C</div>
                    <div class="gyt-page2-box-title">人工精选</div>
                    <div class="gyt-page4-text">定期对产品进行整理与分拣，去除残次品，让购买者购买更放心。</div>
                </div>
            </div>
            <div class="gyt-page5-box">
                <div class="gyt-page1-text gyt-page1-text-bottom">
                    食者无虑，饮者无忧，健康万家
                </div>
            </div>
        </div>
        <!-- <div class="gyt-page1-text">每天一点，健康永远</div> -->
        <div class="gyt-page1-text">关于我们</div>
        <div class="gyt-page6-text">
            上海国延堂医药科技有限公司（以下简称“国延堂”）成立于2003年，于2020年进行重组。<br />
            公司秉承着重点发展医疗大健康产业的战略发展规划，致力于将公司打造成医疗生物技术研发、生产、销售、服务为一体的新一代高科技医疗生物公司。<br />
            公司主要经营业务覆盖中医药滋补品的研发生产和销售、中医院门诊的医疗配套服务、医疗器械设备的研发生产和销售、干细胞技术研究和推广。 <br />
            公司联合全球各地区顶尖医院、高等学府、研究所、医疗专家共同研发医疗设备和产品，并通过并购全球新型创新技术专利赋能公司自身医疗产品线，用以保障人民群众的健康、快乐、富足。 <br />
            董事长施金标先生是中国农工党中央经济委副主任，上海浙江商会副会长。施金标先生师承国学大师王翰之，一直致力传统文化、中医养生保健等学习、研究和开发。 <br />
        </div>
        <div class="gyt-page6-img-box">
            <img class="gyt-page6-img" src="../assets/image/about01.jpg" />
            <img class="gyt-page6-img" src="../assets/image/about02.jpg" />
            <img class="gyt-page6-img" src="../assets/image/about03.jpg" />
            <img class="gyt-page6-img" src="../assets/image/about04.jpg" />
        </div>

        <div class="gyt-page-footer">
            <div>
                <span class="gyt-page-footer-title">公司地址：</span>汉口路551号（市区办公处）<br />
                <span class="gyt-page-footer-title">联系电话：</span>63608008<br />
                <span class="gyt-page-footer-title">邮箱：</span>guoyantangpm@163.com<br />
            </div>
            <div>

                <img class="gyt-page-footer-img" src="../assets/image/gongzh.jpg" />
                <img class="gyt-page-footer-img" src="../assets/image/xiaocx.png" />
            </div>
        </div>

        <div class="gyt-page-keep-record">沪ICP备<a href="http://www.beian.miit.gov.cn/">2020025578号-1</a></div>
    </div>
</template>

<script>
import '../assets/Css/pageCss.css'
export default {
  name: "Main",
  mounted() {
    this.$nextTick(()=>{
      // let doxH = document.documentElement.clientHeight
      // let page = document.getElementsByClassName('page')
      // for (let i = 0; i < page.length; i++) {
      //   page[i].style.height = doxH + 'px'
      // }
    })
  }
}
</script>

<style scoped>
</style>
